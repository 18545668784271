import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ResourcesService } from 'src/app/services/resources.service';
import { Title } from '@angular/platform-browser';
import { CenterLeaderList } from 'src/app/models/CenterLeaderList';
import { Center, CenterLeader } from 'src/app/models/ResponseModel';


@Component({
  selector: 'app-ceo',
  templateUrl: './ceo.component.html',
  styleUrls: ['./ceo.component.css']
})

export class CeoComponent implements OnInit {
  etecceo = '';
  etecLeaderName ='';
  etecLeaderPrefixId ='';
  content ='';
  _lang = '';

  constructor(private apiServices : ApiService, private centerService: ApiService, private actRoute: ActivatedRoute, private Title: Title
    , private apiResources: ResourcesService, private loading: LoadingService) {
    if (!localStorage.getItem('lang')) {
      this._lang = 'ar';
    }
    else {
      this._lang = (localStorage.getItem('lang')) == "en" ? 'en' : 'ar';
    }
  }

  async changeLang(lang: string) {
    this.loading.setLang(lang);
    this.apiResources.clearResource();
  }

  async ngOnInit() {



    this.etecceo = await this.apiResources.getByKey("etecceo");
    this.etecLeaderName = await this.apiResources.getByKey("etecLeaderName");
    this.etecLeaderPrefixId = await this.apiResources.getByKey("etecLeaderPrefixId");
  }

}
