<!-- Nav tabs -->
<ul class="nav nav-tabs content-tabs flex-nowrap justify-content-md-center justify-content-start overflow-auto mb-0 pb-5"
    role="tablist" *ngIf="hasSubContent">
    <li class="nav-item mb-0 ms-lg-4" role="presentation" *ngFor="let link of chilContentList">
        <a class="nav-link text-nowrap fs-sm fw-semibold d-flex align-items-center justify-content-center"
            href="#tab_{{link.ItemId}}" id="tabs-tab1" data-bs-toggle="tab" role="tab" aria-controls="tab1"
            aria-selected="true" style="height:3.75rem;min-width: 12rem;border:2px solid var(--primaryColor) !important"
            [ngClass]="link.ItemId === this.activeId ? 'active  ':''" (click)="activeId = link.ItemId">
            <i class="{{link.ItemFields?.Icon}}" *ngIf="link.ItemFields?.Icon"></i>
            {{link.ItemFields?.PublicContentTitle}}
        </a>
    </li>
</ul>
<!-- Tab content -->
<div class="tab-content rounded-3">
    <!-- tab-pane -->
    <div class="tab-pane fade text-primary" id="#tab_{{link.ItemId}}" role="tabpanel" aria-labelledby="tabs-tab1"
        *ngFor="let link of chilContentList" [ngClass]="link.ItemId === this.activeId ? 'active show ':''"
        >
        <div *ngIf="link.ItemFields?.Title != 'docs'" [innerHTML]="link.ItemFields?.PublicContentDetails"></div>

         <!-- Dynamic Document     -->
        <app-dynamic-document *ngIf="link.ItemFields?.Title === 'docs'"
        [ContentTitle]="link.ItemFields?.PublicContentTitle!" [ItemId]="link.ItemId"></app-dynamic-document>
        <!-- odatagroup -->
        <app-odatagroup *ngIf="link.ItemFields?.Title === 'odatagroup'">

        </app-odatagroup>
    </div>
</div>