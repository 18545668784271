<!--begin::hero-->
<section
    class="position-relative d-flex align-items-center bg-light mb-0 py-lg-5 pt-5 bg-size-cover bg-position-center bg-repeat-0"
    style="background-image: url('assets/media/img/ContactUs.png');height: 300px;">
    <span class="position-absolute top-0 start-0 w-100 h-100 bg-primary opacity-50"></span>
    <div class="container position-relative zindex-5">
        <div class="row justify-content-center">
            <div
                class="col-12 order-md-1 order-2 d-flex flex-column justify-content-between pt-2 align-items-center text-center">
                <!-- Text -->
                <h1 class="h1 text-white fw-semibold m-0 position-relative zindex-5" [innerText]="PageTitle">
                </h1>
            </div>
        </div>
    </div>
</section>
<!--begin::breadcrumb-->
<section class="mb-5 bg-infoLight breadcrumb-containter">
    <div class="container">
        <div class="row">
            <div class="col m-3">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb m-0">
                        <li class="breadcrumb-item"><a href="/home" [innerText]="home" class="fs-sm fw-normal"></a></li>
                        <li class="breadcrumb-item fs-sm fw-normal active" aria-current="page" [innerText]="PageTitle">
                            11 </li>
                    </ol>
                </nav>
            </div>
        </div>
    </div>
</section>

<!--begin::contact us-->
<div class="container">
    <!-- text -->
    <div class="row justify-content-md-start justify-content-center mb-4">
        <div class="col-12">
            <div class="d-flex justify-content-center mb-3">
                <div class="px-3 py-0">
                    <span>
                        <img width="75" class="" src="assets/img/icon/contactUs-icon.svg"/>
                    </span>
                </div>
                <div class="px-3 border-start border-primary d-flex align-items-center">
                    <h3 class="text-primary mb-0" [innerText]="looktoserveyou"></h3>
                </div>

            </div>
        </div>
    </div>
    <!-- map -->
    <div class="row mb-2">
        <div class="col-12" data-aos="fade-up" data-aos-duration="2000">
            <iframe class="d-block h-100"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1811.9614459369507!2d46.59609973224652!3d24.72952734952678!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f1b6ba6fc41f7%3A0xb065eeeb470df366!2z2YfZitim2Kkg2KrZgtmI2YrZhSDYp9mE2KrYudmE2YrZhSDZiNin2YTYqtiv2LHZitioIEVkdWNhdGlvbiAmVHJhaW5pbmcgRXZhbHVhdGlvbiBjb21taXNzaW9u!5e0!3m2!1sen!2sua!4v1659437270435!5m2!1sen!2sua"
                style="border: 0; min-height: 350px;border-radius: 1.375rem;" allowfullscreen=""
                loading="lazy"></iframe>
        </div>
    </div>
    <!-- data -->
    <div class="row row-cols-1 row-cols-lg-2 g-4 text-center d-flex align-items-center my-4 py-3">
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="1500">
            <i class="bx bx-phone text-secondary fs-2"></i>
            <a href="tel:{{unfiedNumber}}"
                class="ps-3 fs-lg fw-normal text-primary mb-0 text-start text-decoration-none"
                [innerText]="unfiedNumber"> </a>
        </div>
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="1800">
            <i class="bx bx-link text-secondary fs-2"></i>
            <a href="{{mailAdderess}}" class="ps-3 fs-lg fw-normal text-primary mb-0 text-start text-decoration-none"
                [innerText]="mailAdderess" target="_blank">
            </a>
        </div>
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="2000">
            <i class="bx bx-stopwatch text-secondary fs-2"></i>
            <span class="ps-3 fs-base fw-normal text-primary mb-0 mb-n1 text-start" [innerText]="workinghours">

            </span>
        </div>
        <div class="col d-flex justify-content-start align-items-center" data-aos="fade-up" data-aos-duration="2200">
            <i class="bx bx-map text-secondary fs-2"></i>
            <a href="https://goo.gl/maps/22s6CgxfVu44e2Kc7"
                class="ps-3 fs-base fw-normal text-primary mb-0 mb-n1 text-start text-decoration-none"
                [innerText]="locationName"> </a>
        </div>
    </div>
    <!--begin::share-->
    <app-shareicons></app-shareicons>
</div>

<script type="text/javascript">

    $(document).ready(function () {
        $('.responsive').slick({
            dots: true,
            infinite: false,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                        infinite: true,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        });
    });
</script>