import { ValidatorFn, AbstractControl } from '@angular/forms';

export function fileExtensionValidator(validExt: string): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
        let forbidden = true;
        if (control.value) {
            const fileExt = control.value.split('.').pop();
            validExt.split(',').forEach(ext => {
                if (ext.trim() == fileExt) {
                    forbidden = false;
                }
            });
        }
        return forbidden ? { 'inValidExt': true } : null;
    };
}
// export function fileMaxSize(maxSize: number): ValidatorFn {
//     return (control: AbstractControl): { [key: string]: any } | null => {
//         let forbidden = true;
//         if (control instanceof File && control.size > 2097152) {
//             forbidden = false;
//         }

//         return forbidden ? { 'inValidSize': true } : null;
//     };
// }

export function fileSizeValidator(maxSize: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file) {
        const fileSize = file.size / 1024; // convert size to KB
        if (fileSize > maxSize) {
          return { fileSizeExceeded: true };
        }
      }
      return null;
    };
  }