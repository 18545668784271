export const environment = {
  production: true,
  baseUrl: 'https://spapi.etec.gov.sa:1443/api/List', // for production live
  //baseUrl: 'https://beta.etec.gov.sa:1443/api/List', // for beat
  // apiUsername: 'admin',
  // apiPassword: 'P@ssw0rd',
  imgLocalUrl: 'http://localhost:4200/',
  imgBaseUrl: 'http://10.10.99.211/',
  imgPublicUrl:'https://spapi.etec.gov.sa:2443/',
  //imgPublicUrl:'https://beta.etec.gov.sa:2443/',
  //imgPublicUrl:'https://cdn.etec.gov.sa/',
  imptempPublicUrl:'http://mediapathurl.com/',
  //tarteebUrl:'https://capi.etec.gov.sa:4443/tarteeb/',
  tarteebUrl:'https://seaapi.etec.gov.sa/tarteeb/ResearchPrize/',
  maxFileSize: 2097152
};
