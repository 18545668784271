import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { LatestnewsComponent } from './components/latestnews/latestnews.component';
import { RelatedlinksComponent } from './components/relatedlinks/relatedlinks.component';
import { StatisticsComponent } from './components/statistics/statistics.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SurvayComponent } from './components/survay/survay.component';
import { AuthInterceptorInterceptor } from './auth-interceptor.interceptor';
import { HomeComponent } from './components/home/home.component';
import { CentersComponent } from './components/centers/centers.component';
import { AboutCenterComponent } from './components/centers/about-center/about-center.component';
import { ProductsComponent } from './components/products/products.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import { ProductsonhomeComponent } from './components/productsonhome/productsonhome.component';

import { CenterLeaderComponent } from './components/center-leader/center-leader.component';
import { CenterServicesComponent } from './components/center-services/center-services.component';
import { QiyasComponent } from './components/eteccenters/qiyas/qiyas.component';
import { NcaaComponent } from './components/eteccenters/ncaa/ncaa.component';
import { NcseeComponent } from './components/eteccenters/ncsee/ncsee.component';
import { MasarComponent } from './components/eteccenters/masar/masar.component';

import { AboutComponent } from './components/about/about.component';
import { EstablishingComponent } from './components/establishing/establishing.component';
import { BoardComponent } from './components/board/board.component';
import { OrganizationalchartComponent } from './components/organizationalchart/organizationalchart.component';
import { VisionComponent } from './components/vision/vision.component';
import { EtecComponent } from './components/eteccenters/etec/etec.component';
import { NewsComponent } from './components/news/news.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

import { MediacenterComponent } from './components/mediacenter/mediacenter.component';
import { InfographicComponent } from './components/infographic/infographic.component';
import { VideolibraryComponent } from './components/videolibrary/videolibrary.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
 
import { IdentityguidelineComponent } from './components/identityguideline/identityguideline.component';
import { AllnewsComponent } from './components/allnews/allnews.component';
import { ProductAccreditationComponent } from './components/product-accreditation/product-accreditation.component';
import { ProductQiyasComponent } from './components/product-qiyas/product-qiyas.component';
import { PartnersComponent } from './components/partners/partners.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ServiceDetailsComponent } from './components/service-details/service-details.component';

import {NgxPrintModule} from 'ngx-print';

import { BrowserAnimationsModule } from'@angular/platform-browser/animations';
import { DataComponent } from './components/reports/data/data.component';
import { PublicResearchComponent } from './components/reports/public-research/public-research.component';
import { ReportsResearchComponent } from './components/reports/reports-research/reports-research.component';
import { ReportsComponent } from './components/reports/reports/reports.component';
import { ScientificActivityComponent } from './components/reports/scientific-activity/scientific-activity.component';
import { ShareiconsComponent } from './components/shareicons/shareicons.component';
import { AccreditationresultComponent } from './components/accreditationresult/accreditationresult.component';
import { ProgramsaccrediationComponent } from './components/programsaccrediation/programsaccrediation.component';
import { InstitutionaccrediationComponent } from './components/institutionaccrediation/institutionaccrediation.component';
import { NotfoundComponent } from './components/shared/notfound/notfound.component';
import { PrivcayComponent } from './components/shared/privcay/privcay.component';
import { ConferenceComponent } from './components/reports/conference/conference.component';
import { WorkshopComponent } from './components/reports/workshop/workshop.component';
import { FAQComponent } from './components/shared/faq/faq.component';
import { CookieService } from 'ngx-cookie-service';
import {  SwiperModule } from "swiper/angular";
import { ImportantlinksComponent } from './components/importantlinks/importantlinks.component';
import { PublicationsandreleasesComponent } from './components/publicationsandreleases/publicationsandreleases.component'
import { ToastrModule } from 'ngx-toastr';
import { RelatednewsComponent } from './components/relatednews/relatednews.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
//import { QRCodeModule } from 'angularx-qrcode';
import { MatInputModule } from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';

import { CenterDetailsComponent } from './components/center-details/center-details.component';
import { NcmaaComponent } from './components/eteccenters/ncmaa/ncmaa.component';
//import { NgxSpinnerModule } from "ngx-spinner";
//import { NgxLoadingXModule } from 'ngx-loading-x';
import { ConnectComponent } from './components/connect/connect.component';
//import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ComboboxComponent } from './components/combobox/combobox.component';
import { LightgalleryModule } from 'lightgallery/angular';
import { PageComponent } from './page/page.component';
import { ServicecatalogComponent } from './components/service-catalog/servicecatalog/servicecatalog.component';
import { ServicecatalogdetailsComponent } from './components/service-catalog/servicecatalogdetails/servicecatalogdetails.component';
import { CenterServiceCatalogComponent } from './components/center-service-catalog/center-service-catalog.component';
import { LightgalleryComponent } from './lightgallery/lightgallery.component';
import { ScrollTotopComponent } from './components/scroll-totop/scroll-totop.component';
import { DynamicnavComponent } from './components/dynamicnav/dynamicnav.component';
import { DynamicCenterComponent } from './components/dynamic-center/dynamic-center.component';
import { GreatingsComponent } from './components/greatings/greatings.component';
import { ThemeMenuComponent } from './components/theme-menu/theme-menu.component';
import { GoalsComponent } from './components/service/goals/goals.component';
import { AudienceComponent } from './components/service/audience/audience.component';
import { TargetedSectorComponent } from './components/service/targeted-sector/targeted-sector.component';
import { StepsComponent } from './components/service/steps/steps.component';
import { ContentComponent } from './components/service/content/content.component';
import { NotesComponent } from './components/service/notes/notes.component';
import { PrerequirementsComponent } from './components/service/prerequirements/prerequirements.component';
import { DocumentComponent } from './components/service/document/document.component';
import { TestTimetableComponent } from './components/service/test-timetable/test-timetable.component';
// import { FaqComponent } from './components/service/faq/faq.component';
import { RelatedserviceGuidComponent } from './components/service/relatedservice-guid/relatedservice-guid.component';
import { FaqListComponent } from './components/service/faq-list/faq-list.component';
import { DynamicDocumentComponent } from './components/dynamic-document/dynamic-document.component';
import { CustomServiceComponent } from './custom-service/custom-service.component';
import { ResearchPrizeComponent } from './forms/research-prize/research-prize.component';
import { ReactiveFormComponent } from './forms/reactive-form/reactive-form.component';
import { ResearcherComponent } from './forms/researcher/researcher.component';
import { ResearcherListComponent } from './forms/researcher-list/researcher-list.component';
import { ResearchDetailsComponent } from './forms/research-details/research-details.component';
import { TranscriptsComponent } from './components/transcripts/transcripts.component';
import  { OpenDataAllComponent } from './components/reports/open-data-all/open-data-all.component';
import { OdatagroupComponent } from './components/reports/odatagroup/odatagroup.component';
import { CeoComponent } from './components/ceo/ceo.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    LatestnewsComponent,
    RelatedlinksComponent,
    StatisticsComponent,
    CarouselComponent,
    SurvayComponent,
    CentersComponent,
    AboutCenterComponent,
    ProductsComponent,
    ProductsonhomeComponent,
    CenterLeaderComponent,
    CenterServicesComponent,
    QiyasComponent,
    NcaaComponent,
    NcseeComponent,
    MasarComponent,
    AboutComponent,
    EstablishingComponent,
    BoardComponent,
    OrganizationalchartComponent,
    VisionComponent,
    EtecComponent,
    NewsComponent,
    ContactUsComponent,
    MediacenterComponent,
    InfographicComponent,
    VideolibraryComponent,
     
    IdentityguidelineComponent,
    AllnewsComponent,
    ProductAccreditationComponent,
    ProductQiyasComponent,
    PartnersComponent,
    ServiceDetailsComponent,
    DataComponent,
    PublicResearchComponent,
    ReportsResearchComponent,
    ReportsComponent,
    ScientificActivityComponent,
    AccreditationresultComponent,
    ProgramsaccrediationComponent,
    InstitutionaccrediationComponent,
    NotfoundComponent,
    PrivcayComponent,
    ConferenceComponent,
    WorkshopComponent,
    FAQComponent,
    ImportantlinksComponent,
    PublicationsandreleasesComponent,
    RelatednewsComponent,
    CenterDetailsComponent,
    NcmaaComponent,
    ConnectComponent,
    SpinnerComponent,
    ComboboxComponent,
    PageComponent,
    ServicecatalogComponent,
    ServicecatalogdetailsComponent,
    CenterServiceCatalogComponent,
    LightgalleryComponent,
    ScrollTotopComponent,
    DynamicnavComponent,
    DynamicCenterComponent,
    GreatingsComponent,
    ThemeMenuComponent,
    GoalsComponent,
    AudienceComponent,
    TargetedSectorComponent,
    StepsComponent,
    ContentComponent,
    NotesComponent,
    PrerequirementsComponent,
    DocumentComponent,
    TestTimetableComponent,
    //FaqComponent,
    RelatedserviceGuidComponent,
    FaqListComponent,
    DynamicDocumentComponent,
    CustomServiceComponent,
    ResearchPrizeComponent,
    ReactiveFormComponent,
    ResearcherComponent,
    ResearcherListComponent,
    ResearchDetailsComponent,
    TranscriptsComponent,
    OpenDataAllComponent,
    OdatagroupComponent,
    CeoComponent
  ],
  
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    Ng2SearchPipeModule,
    MatInputModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatSelectModule,
    ReactiveFormsModule,
    NgxPrintModule,
    NgbModule,
    BrowserAnimationsModule,
    ShareButtonsModule,
    ShareIconsModule,
    NgbModule,
    LightgalleryModule,
    SwiperModule,
    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot(),
    ShareiconsComponent
  ],
  bootstrap: [AppComponent],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorInterceptor,
      multi: true
    },
    Meta
  ]
})
export class AppModule { }
